// @import 'bootstrap/variables';

// utils
.can-click {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.hidden-xl {
  @include media-breakpoint-only(xl) {
    display: none;
  }
}

.hidden-lg {
  @include media-breakpoint-only(lg) {
    display: none;
  }
}

.hidden-md {
  @include media-breakpoint-only(md) {
    display: none;
  }
}

.hidden-sm {
  @include media-breakpoint-only(sm) {
    display: none;
  }
}

.hidden-xs {
  @include media-breakpoint-only(xs) {
    display: none;
  }
}

.modal-backdrop-light {
  background: map-get($map: $theme-colors, $key: light);
  background-color: #544526a1;
  opacity: 1;
}

.modal-backdrop.show {
  opacity: 1;
  background-color: #584a2d21;
}

.modal-backdrop {
  background-color: #0000;
}

.modal-open {
  overflow: auto;
  padding-right: 0 !important;
}

.modal-title {
  font-size: 30px;
  font-weight: 500;
}
