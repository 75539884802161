@import url(https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
// @import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);

/* Font Smoothing */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Poppins, Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: $font-weight-normal;
}

.cr-app {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.event-card {
  border: none;
  transition: all 400ms cubic-bezier(0.19, 1, 0.22, 1);
  overflow: hidden;
  border-radius: 20px;
  min-height: 450px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    min-height: 350px;
  }

  @media (max-width: 420px) {
    min-height: 300px;
  }

  &.card-has-bg {
    transition: all 400ms cubic-bezier(0.19, 1, 0.22, 1);
    background-size: 120%;
    background-repeat: no-repeat;
    background-position: center center;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: inherit;
      -webkit-filter: grayscale(1);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
    }

    &:hover {
      transform: scale(0.9);
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.3);
      background-size: 120%;
      transition: all 400ms cubic-bezier(0.19, 1, 0.22, 1);

      .card-img-overlay {
        transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
        background: rgb(194, 45, 45);
        background: linear-gradient(0deg, rgba(197, 215, 209, 0.208) 0%, rgb(40, 113, 111) 100%);
      }
    }
  }
  .card-footer {
    background: none;
    border-top: none;
    .media {
      img {
        border: solid 3px rgba(234, 95, 0, 0.3);
      }
    }
  }
  .card-meta {
    color: orange;
  }
  .card-body {
    transition: all 400ms cubic-bezier(0.19, 1, 0.22, 1);
  }
  &:hover {
    .card-body {
      margin-top: 30px;
      transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    }
    cursor: pointer;
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  }
  .card-img-overlay {
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    background: rgb(194, 45, 45);
    background: linear-gradient(0deg, rgba(109, 35, 35, 0.379) 0%, rgb(113, 69, 69) 100%);
  }
}

.modal-add-item {
  max-width: 68% !important;
}

.modal-add-event {
  max-width: 68% !important;
}

.modal-dialog {
  max-width: 40%;
}

.isRotated svg {
  transform: rotate(-90deg);
}

.dropdown-item:hover {
  background: #f8f9fa linear-gradient(180deg, #ef8100, #a87723) repeat-x;
}

.sticky-inner-wrapper {
  z-index: 100;
  background-color: #ffffff;
}

.page-item.active a.page-link {
  color: #ffffff !important;
  background-color: #c43535 !important;
  border-color: #ced4da !important;
}
.page-link {
  text-align: center;
  box-shadow: none !important;
  border-color: #ced4da !important;
  color: #000000;
  font-size: 1rem;
}
.page-link:hover {
  background-color: #f4f4f4;
}

.product-bread {
  padding: 2%;
  height: 275px;
  border-top-left-radius: 50%;
}
.product-drink {
  padding: 2%;
  height: 100%;
  max-height: 50vh;
  border-radius: 10%;
}
.product-cake {
  padding: 2%;
  height: 380px;
  border-radius: 10%;
}
