.pwStrRow {
  width: 100%;
  text-align: left;
  margin: auto;
  padding: 0;
  font-size: 12px;
}

.pwStrWeak {
  display: inline-block;
  position: relative;
  padding: 0;
  margin: 0;
  color: gray;
}

.pwStrStrong {
  display: inline-block;
  position: relative;
  padding: 0;
  margin: 0;
  color: gray;
}

.p-wrapper {
  display: flex;
  justify-content: space-between;
}
