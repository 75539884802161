.cr-header {
  margin-bottom: $spacer * .5;
  border-bottom: $border-width solid $border-color;

  &__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
  }

  &__notification-popover {
    &.popover {
      width: 350px;
      max-width: 100%;
      max-height: 50vh;
      overflow-y: scroll;
      scroll-behavior: smooth;
      background-color: #c57171;
    }
  }
}
