.nav-item {
  .nav-link {
    display: flex;
    align-items: center;

    &.active {
      color: #c22d2d;
      background: rgba(255, 255, 255, 0.23);
      opacity: 1;
    }
    &:hover {
      background-color: #f7b6b6;
    }
  }
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
}

.badge:empty {
  display: inline-block;
}
